import React from "react";
import { useForm } from "react-hook-form";
import { ITestUserPostModel, IUpdateParticipantsPostModel, TestUserPostModel, TestUserResponseModel, UpdateParticipantsPostModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { IPagination } from "./consumer-domains-modal.component";
import { getZoomIntegrationsClient } from "../../services/api-clients.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import ReactPaginate from "react-paginate";
import { ReactSelectOption } from "./login-application-select/login-application-select.component";
import Select from "react-select";
import { InputActionMeta } from "react-select";
import moment from "moment";

type ZoomParticipantsSyncModalProps = {

    show: boolean;
    onHide: () => void;
}

export const ZoomParticipantsSyncModal: React.FC<ZoomParticipantsSyncModalProps> = (props) => {
    const { register, handleSubmit, formState, setValue, watch } = useForm<IUpdateParticipantsPostModel>();
    const [isLoading, SetIsLoading] = React.useState<boolean>(false);
    const [pastInstanceOptions, setPastInstanceOptions] = React.useState<ReactSelectOption[]>([]);
    // submit request 
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);

    React.useEffect(() => { getPastWebinarInstances(); }, []);


    const getPastWebinarInstances = async () => {
        try {
            SetIsLoading(true);
            const client = getZoomIntegrationsClient();
            const pastInstancesResp = await client.getUserMeetingOccurrences();
            const mappedPastInstances = pastInstancesResp?.map(x => ({ label: `${moment.utc(x.startedOn).format("DD.MM.YYYY HH:mm")} UTC`, value: x.uuid } as ReactSelectOption)) ?? [];
            setPastInstanceOptions(mappedPastInstances);

        } catch (error) {
            console.log("An error occurred while getting test users.", error);
            ShowErrorFromResponse(error, "An error occurred while getting test users.");
        } finally {
            SetIsLoading(false);
        }
    }

    const onSubmit = async (data: IUpdateParticipantsPostModel) => {

        console.log("Update Participants onSubmit", data);
        try {
            setIsRequestInProgress(true);

            const client = getZoomIntegrationsClient();
            await client.updateUserMeetingParticipants(new UpdateParticipantsPostModel(data));
            toast.success("Successfully synced user meeting participants.");
            props.onHide();

        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while syncing user meeting participants.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }


    const uuid = watch("uuid");
    const selectedUuidOption = pastInstanceOptions?.find(x => x.value == uuid);

    return <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Zoom User Meeting Participants Sync
            </Modal.Title>
        </Modal.Header>
        <form className="form" id="kt_login_signin_form" onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          

                <Select
                    isMulti={false}
                    isClearable
                    placeholder={"Select Meeting"}
                    options={pastInstanceOptions}
                    onChange={(newValue, actionMeta) => {
                        if (actionMeta.action == "select-option") {
                            setValue("uuid", newValue!.value!.toString());
                        } else {
                            setValue("uuid", "");
                        }

                    }} value={selectedUuidOption} />
         

        </Modal.Body>
        <Modal.Footer>
            <div className="form-group d-flex float-right">
                <button type="submit" className={'btn btn-primary'} disabled={isRequestInProgress}>
                    {isRequestInProgress && <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>}
                    Sync
                </button>
                <button type="button" onClick={props.onHide} className={'btn btn-danger ml-2'} disabled={isRequestInProgress}>
                    {"Cancel"}
                </button>

            </div>
        </Modal.Footer>

        </form>


    </Modal>

}