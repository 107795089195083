import React from "react";
import { useForm } from "react-hook-form";
import { ITestUserPostModel, TestUserPostModel, TestUserResponseModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { IPagination } from "./consumer-domains-modal.component";
import { getTestUsersClient } from "../../services/api-clients.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import { toast } from "react-toastify";
import { Button, Modal } from "react-bootstrap";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import ReactPaginate from "react-paginate";

type TestUsersModalProps = {

    show: boolean;
    onHide: () => void;
}

export const TestUsersModal: React.FC<TestUsersModalProps> = (props) => {
    const { register, handleSubmit, formState, setValue } = useForm<ITestUserPostModel>();
    const [testUsers, setTestUsers] = React.useState<TestUserResponseModel[]>([]);
    const [isLoading, SetIsLoading] = React.useState<boolean>(false);
    // submit request 
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);
    const [pagination, setPagination] = React.useState<IPagination>({ currentPage: 1, recordsPerPage: 15, totalRecords: 0 });
    React.useEffect(() => { getTestUsers(); }, [pagination.currentPage]);


    const getTestUsers = async () => {
        try {
            SetIsLoading(true);
            const client = getTestUsersClient();
            const skip = (pagination.currentPage - 1) * pagination.recordsPerPage;

            const testUsersResp = await client.getTestUsers(skip, pagination.recordsPerPage, true);
            if (testUsersResp) {
                setTestUsers(testUsersResp.records ?? []);
                setPagination({ ...pagination, totalRecords: testUsersResp.totalRecords });
            } else {
                setTestUsers([]);
                setPagination({ currentPage: 1, recordsPerPage: 20, totalRecords: 0 });
            }

        } catch (error) {
            console.log("An error occurred while getting test users.", error);
            ShowErrorFromResponse(error, "An error occurred while getting test users.");
        } finally {
            SetIsLoading(false);
        }
    }

    const onSubmit = async (data: ITestUserPostModel) => {

        console.log("add test user onSubmit", data);
        try {
            setIsRequestInProgress(true);

            const client = getTestUsersClient();
            await client.addTestUser(new TestUserPostModel({ userId: data.userId }));
            toast.success("Test user successfully added.");
            setValue("userId", "");
            getTestUsers();
        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while adding test user.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }
    const onRemoveTestUserClick = async (userId: string) => {

        try {
            setIsRequestInProgress(true);

            const client = getTestUsersClient();
            await client.deleteTestUser(userId);
            toast.success("Test user successfully deleted.");
            getTestUsers();
        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while deleting test user.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }


    const totalPages = Math.ceil(pagination.totalRecords / pagination.recordsPerPage);

    return <Modal
        size="xl"
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Test users
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <form className="form" id="kt_login_signin_form" onSubmit={handleSubmit(onSubmit)}>
                <table className="table">
                    <thead>
                        <tr>
                            <th>User id</th>
                            <th>Display name</th>
                            <th>Email</th>
                            <th className="align-middle text-center">Created</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>

                        {testUsers.map((item) => (<tr key={item.userId} >
                            <td>
                                {item.userId}
                            </td>
                            <td>
                                {item.displayName}
                            </td>
                            <td>
                                {item.privateEmail}
                            </td>
                            <td className="align-middle text-center">
                                <DateTimeDisplayUTC date={item.createdAtUtc} />
                            </td>
                            <td className="align-middle text-center"><span style={{ cursor: "pointer" }} onClick={() => onRemoveTestUserClick(item.userId)}><i className="fas fa-times text-danger"></i></span></td>

                        </tr>))
                        }

                        {isLoading &&
                            <tr>
                                <td colSpan={5} className="text-center">Loading...</td>
                            </tr>
                        }
                        {
                            !isLoading && pagination.totalRecords == 0 && <tr>
                                <td colSpan={5} className="text-center">No items found.</td>
                            </tr>
                        }


                    </tbody>
                </table>

                {totalPages > 1 &&
                    <div className="d-flex align-items-center">
                        <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me page-item'}
                            breakLinkClassName={'page-link'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            pageCount={totalPages}
                            marginPagesDisplayed={4}
                            pageRangeDisplayed={10}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            forcePage={pagination.currentPage - 1}
                            onPageChange={(page: any) => {
                                console.log("Selected page:", page);
                                setPagination({ ...pagination, currentPage: page.selected + 1 });
                                document.body.scrollTop = 0; // For Safari
                                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                            }}
                            containerClassName={'pagination mb-0'}
                            activeClassName={'active'}
                        />

                    </div>
                }

            </form>

        </Modal.Body>



    </Modal>

}