
import axios from "axios";
import { AuthenticationClient, ConsumerDomainsClient, DwsimProClient, MSAnalyticsClient, MotherTicketClient, OrganizationsClient as PanelOrganizationsClient, TestUsersClient, UserTagsClient, UsersClient, ZoomIntegrationsClient } from "../swagger-clients/s365-admin-panel-clients.service";
import { UserAgentsClient } from "../swagger-clients/dispatcher-next-admin-clients.service";
import { OrganizationsClient, ResetPasswordClient, UsersClient as DashboardUsersClient } from "../swagger-clients/dashboard-clients.service";
import { ScreenshotsClient as TelemetryScreenshotsClient,FeedbacksClient } from "../swagger-clients/telemetry-clients.service";
import { SensitivityStudiesClient } from "../swagger-clients/sensitivity-studies-clients.service";
import { UserLoginsClient } from "../swagger-clients/s365-usage-tracking-clients.service";

const AxiosInstance = axios.create();
let USER_TOKEN_CACHE: string | null = null;

// Add a request interceptor
AxiosInstance.interceptors.request.use(async function (config) {

    const token = await getUserToken();
    if (token) {
        const authorization = `Bearer ${token}`;
        config.headers.Authorization = authorization;
    }
    return config;
});

var adminPanelUrl = process.env.REACT_APP_BASE_URL;
var dashboardServiceUrl = process.env.REACT_APP_DASHBOARD_SERVICE_URL;
var dispatcherServiceUrl = process.env.REACT_APP_DISPATCHER_SERVICE_URL;
var telemetryServiceUrl = process.env.REACT_APP_TELEMETRY_SERVICE_URL;
var sensitivityStudyServiceUrl = process.env.REACT_APP_SENSITIVITY_STUDY_SERVICE_URL;
var usageTrackingUrl = process.env.REACT_APP_USAGE_TRACKING_URL;

export const getUserToken = async () => 
{
    // Return cached token it it exists
    if (!!USER_TOKEN_CACHE)
        return USER_TOKEN_CACHE;

    // Load new token
    const authClient = new AuthenticationClient(adminPanelUrl);
    try 
    {        
        const token = await authClient.getUserToken();
        USER_TOKEN_CACHE = token;
        return token;
    } 
    catch (error) {
        await authClient.externalLogout();
        window.location.href = `${adminPanelUrl}`;
        throw "Token not aquired.";
    }
}



export const getOrganizationsClient = () => {
    return new OrganizationsClient(dashboardServiceUrl, AxiosInstance);
}

export const getPanelUsersClient = () => {
    return new UsersClient(adminPanelUrl, AxiosInstance);
}

export const getPanelOrganizationsClient = () => {
    return new PanelOrganizationsClient(adminPanelUrl, AxiosInstance);
}

export const getUserAgentsClient = () => {
    return new UserAgentsClient(dispatcherServiceUrl, AxiosInstance);
}

export const getDashboardUsersClient = () => {
    return new DashboardUsersClient(dashboardServiceUrl, AxiosInstance);
}

export const getTelemetryScreenShotsClient = () => {
    return new TelemetryScreenshotsClient(telemetryServiceUrl, AxiosInstance);
}

export const getTelemetryFeedbacksClient = () => {
    return new FeedbacksClient(telemetryServiceUrl, AxiosInstance);
}

// used for all service excel runner, mss, dispatcher etc.
export const getSensitivityStudiesClient = (serviceUrl: string) => {
    return new SensitivityStudiesClient(serviceUrl, AxiosInstance);
}

export const getResetPasswordClientClient = () => {
    return new ResetPasswordClient(dashboardServiceUrl, AxiosInstance);
}

export const getUserLoginsClient = () => {
    return new UserLoginsClient(usageTrackingUrl, AxiosInstance);
}

export const getDwsimProClient = () => {
    return new DwsimProClient(adminPanelUrl, AxiosInstance);
}

export const getCosumerDomainsClient = () => {
    return new ConsumerDomainsClient(adminPanelUrl, AxiosInstance);
}

export const getMotherTicketClient = () => {
    return new MotherTicketClient(adminPanelUrl, AxiosInstance);
}

export const getMsAnalyticsClient = () => {
    return new MSAnalyticsClient(adminPanelUrl, AxiosInstance);
}

export const getTestUsersClient = () => {
    return new TestUsersClient(adminPanelUrl, AxiosInstance);
}
export const getUserTagsClient = () => {
    return new UserTagsClient(adminPanelUrl, AxiosInstance);
}
export const getZoomIntegrationsClient = () => {
    return new ZoomIntegrationsClient(adminPanelUrl, AxiosInstance);
}
