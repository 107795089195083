import * as React from "react";
import { Button, Modal, OverlayTrigger } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { ConsumerDomainPostModel, ConsumerDomainResponseModel, CreateUserTagPostModel, IConsumerDomainPostModel, ICreateUserTagPostModel, UserTagResponseModel } from "../../swagger-clients/s365-admin-panel-clients.service";
import { getUserTagsClient } from "../../services/api-clients.service";
import { ShowErrorFromResponse } from "../../utilities/response-processor";
import ReactPaginate from "react-paginate";
import { DateTimeDisplayUTC } from "../../components/date-time-display/date-time-display.component";
import { get } from "http";


interface IConsumerDomainsModalProps {

    show: boolean;
    onTagsChanged: () => void;
    onHide: () => void;
}

export interface IPagination {
    currentPage: number,
    totalRecords: number,
    recordsPerPage: number
}


const UserTagsModal: React.FunctionComponent<IConsumerDomainsModalProps> = (props) => {


    const { register, handleSubmit, formState, setValue } = useForm<ICreateUserTagPostModel>();
    const [usertags, setUserTags] = React.useState<UserTagResponseModel[]>([]);
    const [isLoading, SetIsLoading] = React.useState<boolean>(false);
    // submit request 
    const [isRequestInProgress, setIsRequestInProgress] = React.useState<boolean>(false);
    const [pagination, setPagination] = React.useState<IPagination>({ currentPage: 1, recordsPerPage: 15, totalRecords: 0 });

React.useEffect(()=>{
    if(props.show){
        getUserTags(); 
    }
},[props.show]);

    React.useEffect(() => { getUserTags(); }, [pagination.currentPage]);


    const getUserTags = async () => {
        try {
            SetIsLoading(true);
            const client = getUserTagsClient();
            const skip = (pagination.currentPage - 1) * pagination.recordsPerPage;

            const userTagsResp = await client.getUserTags(undefined, undefined, skip, pagination.recordsPerPage);
            if (userTagsResp) {
                setUserTags(userTagsResp.items ?? []);
                setPagination({ ...pagination, totalRecords: userTagsResp.totalRecords ?? 0 });
            } else {
                setUserTags([]);
                setPagination({ currentPage: 1, recordsPerPage: 20, totalRecords: 0 });
            }

        } catch (error) {
            console.log("An error occurred while getting user tags.", error);
            ShowErrorFromResponse(error, "An error occurred while getting user tags.");
        } finally {
            SetIsLoading(false);
        }
    }

    const onSubmit = async (data: ICreateUserTagPostModel) => {

        console.log("onSubmit", data);
        try {
            setIsRequestInProgress(true);

            const client = getUserTagsClient();
            await client.createTag(new CreateUserTagPostModel({ name: data.name }));
            toast.success("User tag successfully added.");
            setValue("name", "");
            getUserTags();
            props.onTagsChanged?.();
        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while adding user tag.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }
    const onDeleteTagClick = async (tagId: number) => {

        try {
            setIsRequestInProgress(true);

            const client = getUserTagsClient();
            await client.deleteUserTag(tagId);
            toast.success("User tag successfully deleted.");
            getUserTags();
            props.onTagsChanged?.();
        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while deleting user tag.");
        }
        finally {
            setIsRequestInProgress(false);
        }
    }


    const totalPages = Math.ceil(pagination.totalRecords / pagination.recordsPerPage);

    return <Modal
        size="lg"
        show={props.show}
        onHide={props.onHide}
        animation={false}
    >
        <Modal.Header closeButton={true}>

            <Modal.Title>
                Tags
            </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            <form className="form" id="kt_login_signin_form" onSubmit={handleSubmit(onSubmit)}>
                <div className="d-flex mb-5" style={{ maxWidth: "500px" }}>
                    <div className="d-flex flex-column " style={{ flex: 1 }}>
                        <label className="form-label">Add tag</label>
                        <input
                            className="form-control form-control-sm"
                            type="text"
                            placeholder="Tag name"
                            autoComplete={"false"}
                            {...register("name", { required: true })}
                        />
                        {formState.errors && formState.errors.name && (<p className="text-danger">Tag name is required!</p>)}
                    </div>
                    <div className="ml-4 d-flex flex-column">
                        <label className="form-label">&nbsp;</label>
                        <Button variant="primary" size="sm" type="submit" disabled={isRequestInProgress} style={{ minWidth: "100px" }} >Add</Button>
                    </div>

                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th className="align-middle text-center">Created</th>
                            <th></th>

                        </tr>
                    </thead>
                    <tbody>

                        {usertags.map((item) => (<tr key={item.name} >
                            <td>
                                {item.name}

                            </td>
                            <td className="align-middle text-center">
                                <DateTimeDisplayUTC date={item.createdAtUtc} />
                            </td>
                            <td className="align-middle text-center"><span style={{ cursor: "pointer" }} onClick={() => onDeleteTagClick(item.id)}><i className="fas fa-times text-danger"></i></span></td>

                        </tr>))
                        }

                        {isLoading &&
                            <tr>
                                <td colSpan={3} className="text-center">Loading...</td>
                            </tr>
                        }
                        {
                            !isLoading && pagination.totalRecords == 0 && <tr>
                                <td colSpan={3} className="text-center">No items found.</td>
                            </tr>
                        }


                    </tbody>
                </table>

                {totalPages > 1 &&
                    <div className="d-flex align-items-center">
                        <ReactPaginate
                            previousLabel={'previous'}
                            nextLabel={'next'}
                            breakLabel={'...'}
                            breakClassName={'break-me page-item'}
                            breakLinkClassName={'page-link'}
                            pageClassName={'page-item'}
                            pageLinkClassName={'page-link'}
                            pageCount={totalPages}
                            marginPagesDisplayed={4}
                            pageRangeDisplayed={10}
                            previousClassName={'page-item'}
                            previousLinkClassName={'page-link'}
                            nextClassName={'page-item'}
                            nextLinkClassName={'page-link'}
                            forcePage={pagination.currentPage - 1}
                            onPageChange={(page: any) => {
                                console.log("Selected page:", page);
                                setPagination({ ...pagination, currentPage: page.selected + 1 });
                                document.body.scrollTop = 0; // For Safari
                                document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                            }}
                            containerClassName={'pagination mb-0'}
                            activeClassName={'active'}
                        />

                    </div>
                }

            </form>

        </Modal.Body>



    </Modal>


}

export default UserTagsModal;