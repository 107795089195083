import React from "react";
import Select from "react-select";
import { getUserTagsClient } from "../../services/api-clients.service";

type ReactSelectOption = {
    value: string | number | undefined | null;
    label: string;
}

type UserTagFilterProps = {
    className: string;
    placeholder: string;
    value?: ReactSelectOption;

    onChange?: (value: ReactSelectOption) => void;
}
export type UserTagFilterType = {
    GetOptions: () => void;
}

export const UserTagFilter= React.forwardRef<UserTagFilterType,UserTagFilterProps>((props,ref) => {

    React.useImperativeHandle(ref, () => ({
        GetOptions: () => { GetOptions(); }
    }));
    const [options, setOptions] = React.useState<ReactSelectOption[]>([]);

    React.useEffect(() => {
        GetOptions();
    }, []);

    const GetOptions = async () => {
        try {
            const client = getUserTagsClient();
            const userTagsResp = await client.getUserTags(null, undefined, 0, 10000);
            const mappedOptions = userTagsResp?.items?.map(x => ({ value: x.id, label: x.name } as ReactSelectOption)) ?? [];
            setOptions(mappedOptions);

        } catch (error) {
            console.log("An error occurred while getting user tags.", error);
        }
    }

    return <Select
        placeholder={props.placeholder}
        className={props.className}
        value={props.value}
        isClearable
        options={options}
        onChange={(data) => {
            props.onChange?.(data as ReactSelectOption);
        }}

    />
});