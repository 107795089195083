import React, { useEffect, useState } from 'react';
import { Form, Badge, Button, InputGroup, FormControl, ListGroup } from 'react-bootstrap';
import { getUserTagsClient } from '../../services/api-clients.service';
import { ActionMeta, CSSObjectWithLabel, MultiValue } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import UserTagsService from './user-tags-service';
import { AddUsersTagPostModel, CreateUserTagPostModel, RemoveUsersTagPostModel, UserTagResponseModel } from '../../swagger-clients/s365-admin-panel-clients.service';
import { toast } from 'react-toastify';

export interface TagOption {
    value: string;
    label: string;
}

interface UserTagPickerProps {
    userId: string;
    tags: UserTagResponseModel[];
    onTagsChanged: (tags: TagOption[]) => void;
    onTagCreated: () => void;
}

export type UserTagPickerType = {
    getSuggestions: () => void;
}

const UserTagPicker = React.forwardRef<UserTagPickerType, UserTagPickerProps>((props, ref) => {

    React.useImperativeHandle(ref, () => ({
        getSuggestions: () => { getSuggestions(true); }
    }));

    const [currentValue, setCurrentValue] = React.useState<string>("");
    const [selectedValues, setSelectedValues] = React.useState<TagOption[]>();

    React.useEffect(() => {
        const tags = props.tags?.map(tag => ({ value: tag.id.toString(), label: tag.name } as TagOption)) ?? [];
        setSelectedValues(tags);
    }, [props.tags]);


    const getSuggestions = async (ignoreCache?: boolean) => {
        try {

            const userTagsResp = await UserTagsService.getUserTagsPromise(ignoreCache);
            const mappedOptions = userTagsResp.items?.map(x => ({ value: x.id.toString(), label: x.name } as TagOption)) ?? [];
            return mappedOptions
        } catch (error) {
            console.error("Error fetching tags:", error);
            return [];
        }
    };
    const addSelectedSuggestion = async (tag: TagOption) => {
        try {
            const client = getUserTagsClient();

            const model = new AddUsersTagPostModel({
                tagId: +tag.value,
                userId: props.userId
            });

            await client.addUsersTag(model);
            props.onTagsChanged([...(selectedValues ?? []), tag]);

        } catch (error) {
            toast.error("An error occurred while adding tag.");
        }
    }

    const addTagByName = async (tagName: string) => {

        try {
            const client = getUserTagsClient();
            const createTagModel = new CreateUserTagPostModel({
                name: tagName
            });
            const createdTag = await client.createTag(createTagModel);
            const createTagOption = { value: createdTag.id.toString(), label: createdTag.name } as TagOption;
            await addSelectedSuggestion(createTagOption);
            props.onTagsChanged([...(selectedValues ?? []), createTagOption]);
            props.onTagCreated();

        } catch (error) {
            toast.error("An error occurred while adding tag.");
        }
    }

    const onDeleteTag = async (tagId: number) => {
        try {
            const client = getUserTagsClient();
            const model = new RemoveUsersTagPostModel({
                tagId: tagId,
                userId: props.userId
            });
            await client.removeUsersTag(model);
            const filteredValues = selectedValues?.filter(x => +x.value !== tagId) ?? [];
            props.onTagsChanged([...(filteredValues ?? [])]);

        } catch (error) {
            toast.error("An error occurred while deleting tag.");
        }
    }

    const onTagsChanged = async (selectedOptions: MultiValue<TagOption>, actionMeta: ActionMeta<TagOption>) => {
        console.log("onTagsChanged", selectedOptions, actionMeta);

        // on option selected
        if (actionMeta.action == "select-option") {
            const selectedOption = selectedOptions[selectedOptions.length - 1];
            await addSelectedSuggestion(selectedOption);
        }

        // on tag removed
        if (actionMeta.action == "remove-value") {
            onDeleteTag(+actionMeta.removedValue.value);
        }
    }

    const getMultiValueLabelStyles = (provided: CSSObjectWithLabel) => {
        let styles = {
            lineHeight: "normal"
        } as CSSObjectWithLabel;
        return { ...provided, ...styles };
    }

    const getControlStyles = (provided: CSSObjectWithLabel) => {
        let styles = {
            border: "none !important",
            boxShadow: "none",
            background: "inherit"
        } as CSSObjectWithLabel;
        return { ...provided, ...styles };
    }


    return <AsyncCreatableSelect
        styles={{
            multiValueLabel: getMultiValueLabelStyles,
            control: getControlStyles,
            indicatorSeparator: (provided) => ({ ...provided, display: "none" }),
            indicatorsContainer: (provided) => ({ ...provided, display: "none" }),
            menu: (base) => ({...base,  width: "max-content", minWidth: "100%" }),
            //  multiValueRemove: (provided) => ({ ...provided, display: props.hasRemove ? "flex" : "none" }),         

        }}
        cacheOptions
        value={selectedValues}
        isMulti
        isClearable={false}
        placeholder=""
        defaultOptions={true}
        loadOptions={() => getSuggestions()}
        onInputChange={(newValue: string) => { setCurrentValue(newValue); }}
        onCreateOption={addTagByName}
        onChange={onTagsChanged}


    />


});

export default UserTagPicker;