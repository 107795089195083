import * as React from 'react';
import { BlockUserPostModel, TestUserPostModel, UserResultModel, UsersClient } from "../../../swagger-clients/s365-admin-panel-clients.service";
import { toast } from "react-toastify";
import LoadingService from "../../../components/loading-indicator/loading-indicator.service";
import { getDashboardUsersClient, getPanelUsersClient, getTestUsersClient } from '../../../services/api-clients.service';
import { RegistrationConfirmationPostModel } from '../../../swagger-clients/dashboard-clients.service';
import { ResetPasswordModal } from '../reset-password-modal.component';
import ToggleButton from 'react-bootstrap/ToggleButton';
import { EmailWithLinkToDomain } from '../../../components/email-with-link-to-domain/email-with-link-to-domain.component';
import targetProcessLogo from "../../../assets/images/target-process-logo.png";
import { TargetProcessLink } from '../../../components/target-process-link/target-process-link.component';
import CreateMotherTicketModal from '../create-mother-ticket-modal.component';
import { CreateMotherTicketButton } from '../create-mother-ticket-button.component';
import { DateTimeDisplayUTC } from '../../../components/date-time-display/date-time-display.component';
import { ShowErrorFromResponse } from '../../../utilities/response-processor';

interface IUserGeneralDataProps {
    userId: string;
    onMotherTicketChanged: (motherTicketId: number, userId: string) => void;
}

const UserGeneralData: React.FunctionComponent<IUserGeneralDataProps> = (props) => {

    const [user, setUser] = React.useState<UserResultModel>();
    const [showResetPasswordModal, setShowResetPasswordModal] = React.useState<boolean>(false);
    const [requestInProgress, setRequestInProgress] = React.useState<boolean>(false);
    const [showCreateMotherTicketModal, setShowCreateMotherTicketModal] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (props.userId && props.userId.length > 0) {
            getUserDetails();
        }

    }, [props.userId]);

    const getUserDetails = async () => {
        try {
            LoadingService.showLoading();

            const client = new UsersClient(process.env.REACT_APP_BASE_URL);
            const userDetails = await client.getUserDetails(props.userId);
            setUser(userDetails);


        } catch (error) {
            console.log("An error occurred while getting user details.", error);
            toast.error("An error occurred while getting user details.");
        } finally {
            LoadingService.hideLoading();
        }
    }

    const resetPasswordClick = async () => {
        try {
            setRequestInProgress(true);
            var client = getDashboardUsersClient();


        } catch (error) {
            console.log("An error occurred while sending confirmation mail.", error);
        } finally {
            setRequestInProgress(false);
        }
    }

    const onBlockUserClick = async (user: UserResultModel) => {
        try {
            setRequestInProgress(true);
            const client = getPanelUsersClient();
            const model = {
                isAccountEnabled: user.isBlocked,
                userId: user.id
            } as BlockUserPostModel;
            await client.blockUnblockUser(model);
            getUserDetails();
            toast.success(`User successfully ${model.isAccountEnabled ? 'unblocked' : 'blocked'}.`);

        } catch (error) {
            console.log("An error occurred while blocking / unblocking user.", error);
            toast.error("An error occurred while blocking / unblocking user.");
        } finally {
            setRequestInProgress(false);
        }
    }

    const sendConfirmationMail = async () => {
        try {
            setRequestInProgress(true);
            var client = getDashboardUsersClient();
            var accountConfirmationUrl = process.env.REACT_APP_ACCOUNT_CONFIRMATION_PAGE;
            var email = user?.email;
            if (accountConfirmationUrl && email) {
                await client.resendEmailConfirmation({
                    email: email,
                    accountConfirmationPage: accountConfirmationUrl
                } as RegistrationConfirmationPostModel);
                toast.success("Registration confirmation request sent.");

            } else {
                console.log("Email, accountConfirmationUrl", email, accountConfirmationUrl);
                toast.error("Email or account confirmation url missing.");
            }

        } catch (error) {
            console.log("An error occurred while sending confirmation mail.", error);
        } finally {
            setRequestInProgress(false);
        }
    }

    const onAddTestUserClick = async () => {
        try {
            setRequestInProgress(true);
            const client = getTestUsersClient();
            await client.addTestUser(new TestUserPostModel({ userId: props.userId }));
            toast.success("Test user successfully added.");
            getUserDetails();

        } catch (error) {
            ShowErrorFromResponse(error, "An error occurred while adding test user.");
        }
        finally {
            setRequestInProgress(false);
        }
    }

    return <dl className="row mb-0 mt-4 ml-2 align-items-center">
        <dt className="col-sm-3">Display name:</dt>
        <dd className="col-sm-9">{user ? user.displayName : ""}</dd>

        <dt className="col-sm-3">User principal name:</dt>
        <dd className="col-sm-9">{user ? user.userPrincipalName : ""}</dd>

        <dt className="col-sm-3">Email:</dt>
        <dd className="col-sm-9">
            <EmailWithLinkToDomain email={user?.email} />
            {user?.targetProcesMotherTicketIds?.map((ticketId) => {
                return <TargetProcessLink targetProcesMotherTicketId={ticketId} />
            })
            }
            {(!user?.targetProcesMotherTicketIds || user.targetProcesMotherTicketIds.length == 0) &&
                <CreateMotherTicketButton
                    onClick={() => { setShowCreateMotherTicketModal(true); }}
                />}
        </dd>
        <dt className="col-sm-3">Organization email:</dt>
        <dd className="col-sm-9">{!!user?.submittedOrganizationEmail ? user.submittedOrganizationEmail : "-"}</dd>
        <dt className="col-sm-3">Organization:</dt>
        <dd className="col-sm-9">{!!user?.organization?.name ? user.organization.name : "-"}</dd>
        <dt className="col-sm-3" style={{ alignSelf: "flex-start" }}>Groups:</dt>
        <dd className="col-sm-9">{
            !!user?.userGroups && user.userGroups.length > 0 &&
            <div style={{ display: "flex", flexDirection: "column" }}>
                {user.userGroups.map(groupName => (<span>{groupName}</span>))}
            </div>}
        </dd>

        <dt className="col-sm-3">Created at:</dt>
        <dd className="col-sm-9">{user ? <DateTimeDisplayUTC date={user.createdAt} /> : ""}</dd>

        {user && <>
            <dt className="col-sm-3">Email confirmed at:</dt>
            <dd className="col-sm-9">{(() => {
                if (user.confirmedRegistrationAt) {
                    return <DateTimeDisplayUTC date={user?.confirmedRegistrationAt} />
                }
                else {
                    return <div style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
                        <span className="mr-3">{user.createdAt < new Date("2021-07-01") ? <small><em>User was registered before functionality was available.</em></small> : <span>Not confirmed</span>}</span>
                        <button
                            type="button"
                            disabled={requestInProgress}
                            onClick={sendConfirmationMail}
                            className="btn btn-sm btn-primary">Resend</button>
                    </div>
                }
            })()}
            </dd>
        </>}

        <dt className="col-sm-3">Id:</dt>
        <dd className="col-sm-9">{user ? user.id : ""}</dd>

        <dt className="col-sm-3">Dashboard EULA:</dt>
        <dd className="col-sm-9">{user && !user.acceptedEulaDashboard && <>No</>}
            {user && user?.accepetedEulaFromDashboardAt && <>Yes (<DateTimeDisplayUTC date={user?.accepetedEulaFromDashboardAt} />)</>}
        </dd>
        <dt className="col-sm-3">DWSIM Pro EULA:</dt>
        <dd className="col-sm-9">{user && !user.acceptedEulaDwsimPro && <>No</>}
            {user && user?.accepetedEulaFromDwsimProAt && <>Yes (<DateTimeDisplayUTC date={user?.accepetedEulaFromDwsimProAt} />)</>}
        </dd>

        <dt className="col-sm-3">Watched Tour:</dt>
        <dd className="col-sm-9">
            {user && !!user.tourWatched && <>Yes</>}
            {user && !user.tourWatched && <>No</>}
            {!user && <>-</>}
        </dd>


        {user && <>
            <dt className="col-sm-3">Reset password:</dt>
            <dd className="col-sm-9">
                <div style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
                    <button
                        type="button"
                        onClick={() => setShowResetPasswordModal(true)}
                        className="btn btn-sm btn-primary">Reset</button>
                </div>

            </dd>
        </>}

        <dt className="col-sm-3">Blocked sign in:</dt>
        <dd className="col-sm-9">
            <div style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
                <span className="mr-3">  {user ? user.isBlocked ? "Yes" : "No" : ""}</span>
                <button
                    type="button"
                    disabled={requestInProgress}
                    onClick={() => { onBlockUserClick(user!) }}
                    className="btn btn-sm btn-primary">{user ? user.isBlocked ? "Unblock" : "Block" : ""}</button>
            </div>

        </dd>

        <dt className="col-sm-3">Test user:</dt>
        <dd className="col-sm-9">
            <div style={{ display: "flex", flexDirection: 'row', alignItems: "center" }}>
                <span className="mr-3">  {user ? user.isTestUser ? "Yes" : "No" : ""}</span>
                {!!user && !user.isTestUser && <button
                    type="button"
                    disabled={requestInProgress}
                    onClick={() => { onAddTestUserClick() }}
                    className="btn btn-sm btn-primary">Add test user</button>}
            </div>

        </dd>

        {showResetPasswordModal && <ResetPasswordModal show={true} onHide={() => setShowResetPasswordModal(false)} userDisplayName={user?.displayName!} userId={user?.id!} />}
        {showCreateMotherTicketModal && user &&
            <CreateMotherTicketModal
                show={true}
                user={user}
                onSuccess={(motherTicketId: number) => {
                    setUser({ ...user, targetProcesMotherTicketIds: [...(user.targetProcesMotherTicketIds ?? []), motherTicketId] } as UserResultModel);
                    setShowCreateMotherTicketModal(false);
                    props.onMotherTicketChanged(motherTicketId, user.id!);
                }}
                onHide={() => { setShowCreateMotherTicketModal(false); }}

            />}

    </dl>;
}

export default UserGeneralData;