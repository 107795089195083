import { getUserTagsClient } from "../../services/api-clients.service";
import { ListResultOfUserTagResponseModel, UserTagResponseModel } from "../../swagger-clients/s365-admin-panel-clients.service";

class UserTagsService {
    private static __requestsCache: { [key: string]: Promise<ListResultOfUserTagResponseModel> } = {};

    public static getUserTagsPromise(ignoreCache: boolean = false) {
        // Create Promise for loading data, it it doesn't exist already
        const cacheKey = getCurrentDateString();
        if (UserTagsService.__requestsCache[cacheKey] === undefined || ignoreCache) {
            let client = getUserTagsClient();
            UserTagsService.__requestsCache[cacheKey] = client.getUserTags(null, undefined, 0, 10000);
        }

        return UserTagsService.__requestsCache[cacheKey];
    }
}

const getCurrentDateString = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year} ${month} ${day} ${hours}:${minutes}`;
};


export default UserTagsService;